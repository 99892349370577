import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>$YETI</p>
        {/* <video className="App-logo"  loop>
          <source src="yetivid.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video> */}
        <img src="./yeti.jpg" className="App-logo" alt="logo" />
        <a
          className="App-link"
          href="https://twitter.com/yeticupcoin"
          target="_blank"
          rel="noopener noreferrer"
        >
          TWITTER
        </a>
        <a
          className="App-link"
          href="http://t.me/yeticupthecoin"
          target="_blank"
          rel="noopener noreferrer"
        >
          TELEGRAM
        </a>
        <p>CA: 28NB6EymwHx7w7rx1hkRVrDBsEVWa3E4ZjFZySsAoN1h</p>
      </header>
    </div>
  );
}

export default App;
